import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { styled } from '@material-ui/styles';

import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({ className, children }) => (
  <StaticQuery query={graphql`
      query {
        desktop: file(relativePath: { eq: "Chicken_Sandwich_dark.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`#040e18`}
        >
          {children}
        </BackgroundImage>
      )
    }
    }
  />
)

const StyledBackgroundSection = styled(BackgroundSection)({
  width: '100%',
  backgroundAttachment: 'fixed',
  '@media(max-width: 600px)': (
    {
      'backgroundAttachment': 'scroll !important',
      '&::after': {
        'backgroundAttachment': 'scroll !important',
      },
      '&::before': {
        'backgroundAttachment': 'scroll !important',
      }
    }
  ),
  backgroundPosition: 'center center',
  backgroundRepeat: 'repeat-y',
  backgroundSize: 'cover',
  minHeight: '70vh',
  color: 'white',
  display: 'flex',
  alignItems: 'center'
});

export default StyledBackgroundSection