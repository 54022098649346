import React from "react"

import { Link } from "gatsby"
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import StarIcon from '@material-ui/icons/Star';

import Layout from "../layout"
import SEO from "../components/seo"

// Sectionals
import Hero from "../components/heros/index"
import Sectional1 from "../components/index/sectional-1"

const IndexPage = () => {
  const largeScreen = useMediaQuery('(min-width:600px)');

  return (
    <Layout>
      <SEO title="" />
  
      <Hero>
        <Container fixed style={{ textAlign: 'center', paddingTop: '4rem' }}>
          <Typography variant="h1" style={{ marginBottom: '2rem' }}>
            Cluck Chicken
          </Typography>
          <Typography variant="h4" >
            Southern Style Comfort Food. Specializing in hand breaded chicken fingers and delicious dipping sauces.
          </Typography>
        </Container>
      </Hero>
  
      <Container fixed style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
        <Typography style={{ fontSize: '1.2rem', textAlign: 'center' }}>
          <Typography variant="h4">
            NOW OPEN
          </Typography>
          <Typography variant="p" style={{ fontSize: '1rem', textAlign: 'center' }}>
            What started as a small food truck then into Denver's favorite in-bar restaurant we are excited to announce our next big leap. We are now are moving right next door into a new larger kitchen and expanding our hours and menu. We hope to be having a new grand opening in soon and can't wait to see you there.
          </Typography>
          <br /><hr />
          <StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon /><br />
          "Sooo good! Fresh and delicious. Best fried chicken I've had in Denver hands down! Thanks guys!" - Jessica F.
          <br /><br />
          <StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon /><br />
          "Great service, awesome food! This is the best basket of chicken tenders I've ever had" - Chelsea A.
          <br /><br />
          <StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon /><br />
          "Seriously, the BEST chicken, homemade sauce AND homemade pickles in Denver!!! The only place I'll go for chicken fingers" - Kendalle E.
          <br /><br />
          <Button component={Link} to="/menu/" color="secondary" variant="outlined">See our menu</Button>

        </Typography>
      </Container>
  
      <Sectional1 />
  
      <Container fixed style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
        <Typography variant="h4" style={{ textAlign: 'center'}}>
          Cluck Yes
        </Typography>
        <Typography style={{fontSize: '1.2rem'}}>
          <p>Southern-style fried chicken done right.</p>
          <p>Denver’s favorite chicken has a new home. In collaboration with the Dive Inn (of 5280 magazines <OutboundLink href="https://www.5280.com/2020/01/10-of-denvers-best-dive-bars/" target="_blank">Denver’s best 10 Dive bars</OutboundLink>) we’ve brought the finest, crispiest, juiciest, most finger-licking fried chicken to South Broadway.</p>
          <p>And we know a thing or two about frying chicken. With roots deep in the heart of Alabama we hand-bread our chicken in house using our secret family recipe before we deep-fry those cluckers to mouth-watering perfection.</p>
          <p>So swing by and grab yourself a basket, a sandwich, wings and some ofour out-of-this world dipping sauces and experience one of Denver’s best comfort food eateries.</p>
          <p>Do yourself a favor.<br /> Get clucked.<br /><br /> On-site parking available.</p>
        </Typography>
      </Container>
  
      {largeScreen &&
        <Sectional1 />
      }   
  
    </Layout>
  )
}


export default IndexPage
